<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#9b221f"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#9b221f" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <!-- <v-layout wrap justify-end>
      <v-flex xs12 sm6 md3 pa-5>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDate"
              label="From Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fromDate"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12 sm6 md3 pa-5>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDate"
              label="To Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="toDate"
            no-title
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs12 sm6 md3 lg3 xl4 pa-5>
        <v-select
          v-model="selectedFilter"
          :items="dateFilter"
          item-text="title"
          item-value="value"
          outlined
          dense
          label="Date"
        ></v-select>
      </v-flex>
      <v-flex xs3 align-self-center>
        <v-spacer></v-spacer>
      </v-flex>
      <v-flex align-self-start xs12 sm6 md3 lg2 xl4 v-if="quoteData.length > 0">
        <a
          target="_blank"
          :href="
            baseURL +
            '/purchase/getDetailedReportPdf?from=' +
            fromDate +
            '&to=' +
            toDate +
            '&filter=' +
            selectedFilter
          "
          download
          class="downlink"
        >
          <span
            style="
              font-size: 16px;
              font-family: poppinssemibold;
              color: #9b221f;
            "
          >
            Download PDF</span
          >
        </a>
      </v-flex>
      <v-flex align-self-start xs12 sm6 md3 lg2 xl4 v-if="quoteData.length > 0">
        <download-excel :data="json_data" :fields="json_fields">
          <span
            style="
              font-size: 16px;
              font-family: poppinssemibold;
              color: #9b221f;
              cursor: pointer;
            "
          >
            Download Excel</span
          >
        </download-excel>
      </v-flex>
    </v-layout> -->
    <v-layout wrap v-if="quoteData.length > 0">
      <v-flex align-self-center xs12 pt-4>
        <v-layout wrap>
          <v-flex
            xs12
            align-self-center
            pa-2
            v-for="(main, m) in quoteData"
            :key="m"
          >
            <v-layout wrap justify-start v-if="main">
              <v-flex xs12 md10 align-self-center pt-4 v-if="main.carts">
                <v-card tile outlined>
                  <v-layout wrap justify-start v-if="main.carts.length > 0">
                    <v-flex xs12 text-right pa-2>
                      <a 
                        :href="
                          baseURL + '/productcart/quotation/adminpdfget?id=' + main._id
                        "
                        class="downlink"
                      >
                        <span
                          style="
                            font-size: 16px;
                            font-family: poppinssemibold;
                            color: #9b221f;
                          "
                        >
                          Download Quotation</span
                        >
                      </a>
                    </v-flex>
                    <v-flex
                      xs12
                      align-self-center
                      v-for="(item, i) in main.carts"
                      :key="i"
                    >
                      <v-card outlined tile>
                        <v-layout pa-3 wrap justify-start>
                          <v-flex xs12 sm6 text-left>
                            <span class="itemCaption" style="font-size: 18px">
                              {{ item.product.name }}
                            </span>
                            <!-- <span
                    class="cardSubHeading text-uppercase"
                    style="font-size: 14px"
                  >
                    {{ main.deliverystatus }}
                  </span> -->
                          </v-flex>

                          <v-flex xs12>
                            <v-layout wrap pa-2>
                              <v-flex xs4 lg2 align-self-center>
                                <v-img
                                  v-if="item.product.images.length > 0"
                                  width="175px"
                                  height="113px"
                                  :src="mediaURL + item.product.images[0]"
                                >
                                </v-img>
                              </v-flex>
                              <v-flex xs8 lg10 pl-4>
                                <v-layout wrap justify-start> </v-layout>
                                <v-layout wrap pt-2>
                                  <v-flex md6 text-left>
                                    <span class="textField">Created Date</span
                                    ><br />
                                    <span
                                      v-if="main.create_date"
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                      >{{ formatDate(main.create_date) }}
                                    </span>
                                    <v-layout wrap>
                                      <v-flex md12 text-left>
                                        <span class="slotText"
                                          >Purchaser Details</span
                                        >
                                      </v-flex>
                                      <v-flex md12 text-left>
                                        <span class="tableValue1">{{
                                          main.user.name
                                        }}</span>
                                        <br />
                                        <span class="tableValue1">{{
                                          main.user.email
                                        }}</span>
                                        <br />
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>

                                  <!-- <v-flex md6 text-left>
                          <span class="textField"> Date</span><br />
                          <span
                            class="cardSubHeading"
                            style="letter-spacing: 0px"
                            >{{ formatDate(main.payment.init_date) }}
                          </span>
                          <v-layout wrap>
                            <v-flex md12 text-left>
                              <span class="textField"> Details</span>
                            </v-flex>
                            <v-flex md12 text-left>
                              <span class="tableValue1">{{
                                main.payment.name
                              }}</span>
                              <br />
                              <span class="tableValue1">{{
                                main.payment.email
                              }}</span>
                              <br />
                            </v-flex>
                          </v-layout>
                        </v-flex> -->

                                  <v-flex md6>
                                    <v-layout wrap>
                                      <v-flex md12 text-left>
                                        <span class="textField"> Address</span>
                                      </v-flex>
                                      <v-flex
                                        md12
                                        text-left
                                        v-if="main.deliveryaddress"
                                      >
                                        <span class="tableValue1"
                                          >{{ main.deliveryaddress.firstname }}
                                          {{
                                            main.deliveryaddress.lastname
                                          }}</span
                                        >
                                        <br />
                                        <span class="tableValue1">{{
                                          main.deliveryaddress.email
                                        }}</span>
                                        <br />
                                        <span class="tableValue1">{{
                                          main.deliveryaddress.phone
                                        }}</span>
                                        <br />
                                        <span class="tableValue1">{{
                                          main.deliveryaddress.streetAddress
                                        }}</span>
                                        <br />
                                        <span class="tableValue1"
                                          >{{ main.deliveryaddress.city }}
                                          {{ main.deliveryaddress.district }}
                                          {{ main.deliveryaddress.state }}</span
                                        >
                                        <br />
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                            <!-- <v-divider></v-divider>
                  <v-layout wrap v-if="main.trackingid" pa-3>
                    <v-flex md4 text-left>
                      <span v-if="main.courierservice == 'Delhivery'">
                        <span class="slotText">Tracking URL</span>
                      </span>
                      <span v-else class="slotText">Tracking Id</span>
                    </v-flex>
                    <v-flex md8 text-right>
                      <span
                        class="tableValue1"
                        v-if="main.courierservice == 'Delhivery'"
                      >
                        <a :href="main.trackingid">
                          <span class="tableValue1" style="color: #9b221f">{{
                            main.trackingid
                          }}</span>
                        </a>
                      </span>
                      <span v-else class="tableValue1">{{
                        main.trackingid
                      }}</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap v-if="main.courierservice">
                        <v-flex md4 text-left>
                          <span class="slotText">Courier Service</span>
                        </v-flex>
                        <v-flex md8 text-right>
                          <span class="tableValue1">{{
                            main.courierservice
                          }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-divider></v-divider>
                  <v-layout wrap pa-4>
                    <v-flex md4 text-left v-if="main.unitname">
                      <span class="slotText1">Type</span>
                    </v-flex>
                    <v-flex md8 text-right v-if="main.unitname">
                      <span class="tableValue1">{{ main.unitname }}</span>
                    </v-flex>
                    <v-flex md4 text-left>
                      <span class="slotText1">Quantity</span>
                    </v-flex>
                    <v-flex md8 text-right>
                      <span class="tableValue1"
                        >{{ main.sizename }} x {{ main.quantity }}</span
                      >
                    </v-flex>
                  </v-layout> -->
                            <v-divider></v-divider>
                            <v-layout wrap pa-4>
                              <v-flex md12 text-left>
                                <span class="textField">Payment Details</span>
                              </v-flex>
                              <v-flex md12 text-left>
                                <v-layout wrap>
                                  <v-flex md4 text-left>
                                    <span class="slotText1">Unit Price</span>
                                  </v-flex>
                                  <v-flex md8 text-right>
                                    <span class="tableValue1"
                                      >{{ item.productPrice }} INR</span
                                    >
                                  </v-flex>
                                </v-layout>
                                <v-layout wrap>
                                  <v-flex md4 text-left>
                                    <span class="slotText1">Quantity</span>
                                  </v-flex>
                                  <v-flex md8 text-right>
                                    <span class="tableValue1"
                                      >{{ item.quantity }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                                <v-layout wrap>
                                  <v-flex md4 text-left>
                                    <span class="slotText1">Amount</span>
                                  </v-flex>
                                  <v-flex md8 text-right>
                                    <span class="tableValue1"
                                      >{{ item.productTotalPrice }} INR</span
                                    >
                                  </v-flex>
                                </v-layout>

                                <v-layout wrap>
                                  <v-flex md4 text-left>
                                    <span class="slotText1"
                                      >Payment Gateway Charge</span
                                    >
                                  </v-flex>
                                  <v-flex md8 text-right>
                                    <span class="tableValue1"
                                      >{{
                                        Math.round(item.paymentgatewayCharge)
                                      }}
                                      INR</span
                                    >
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-layout wrap pa-4>
                      <v-flex md4 text-left>
                        <span class="slotText1">GRAND TOTAL</span>
                      </v-flex>
                      <v-flex md8 text-right>
                        <span class="tableValue1"
                          >{{ Math.round(main.finalAmount) }} INR</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap v-else>
      <v-flex xs12 py-4>
        <span style="font-family: poppinsregular"> No Quotations found!! </span>
      </v-flex>
    </v-layout>
    <v-dialog v-model="showDesc" max-width="900px">
      <v-card>
        <v-card-title>
          <span style="font-family: poppinsbold" v-html="heads"></span>
        </v-card-title>

        <v-card-subtitle style="padding: 5%">
          <span v-html="description"></span>
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="showDesc = false">Close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#9b221f"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageLoader from "@/components/Common/imageLoader";
import axios from "axios";
export default {
  // props: ["booking", "pages", "count"],
  data() {
    return {
      currentPage: 1,
      cancelOrderDialog: false,
      cancelFullDialog: false,
      orderItems: [],
      programData: {},
      cancelAll: false,
      progName: "",
      bookingsId: "",
      ticketsId: "",
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      isFullRefund: false,
      checkbox: false,
      allChecked: false,
      ticketIds: [],
      selectedDialog: false,
      totalSelectectedAmt: 0,
      cottageTotal: 0,
      quoteData: [],
      pages: 0,
      count: 8,
      selectedFilter: "",
      dateFilter: [
        { title: "Past month", value: "1" },
        { title: "Past three months", value: "2" },
        { title: "Past financial year", value: "3" },
      ],
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      showDesc: false,
      description: "",
      heads: "",

      excel: [],
      json_fields: {
        Order_No: "txnid",
        Invoice_No: "receiptno",
        "Customer Name": "payment.name",
        "Item Name": "product.name",
        "Item Type": "unitname",
        "Item Size": "sizename",
        "Quantity Purchased": "quantity",
        "Phone No": "payment.deliveryaddress.phone",
        Email: "payment.deliveryaddress.email",
        Address: "payment.deliveryaddress.streetAddress",
        "Date of placing of order": "payment.init_date",
        "Order Dispatched On": "shipped_date",
        "Courier Service": "courierservice",
        "Tracking ID": "trackingid",
        "Order Delivered on": "delivered_date",
        "Amount settled(total-PG)": "amountWithoutPG",
        "Amount Paid(Total)": "total",
      },
      json_fieldsReservation: {
        ProgrameName: "programmeName",
        BookingDate: "bookingDate",
        "Seats Reserved": "reserved",
        StartTime: "slotDetail.startTime",
        EndTime: "slotDetail.endTime",
      },
      json_data: [],
      json_dataReservation: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  components: {
    // ImageLoader,
  },
  watch: {
    currentPage() {
      this.getData();
    },
    selectedFilter() {
      if (this.currentPage > 1) this.currentPage = 1;

      this.fromDate = null;
      this.toDate = null;
      this.getData();
      // this.getExcel();
    },
    fromDate() {
      this.selectedFilter = null;
      this.getData();
      // this.getExcel();
    },
    toDate() {
      this.selectedFilter = null;
      this.getData();
      // this.getExcel();
    },
  },
  mounted() {
    // console.log("hk", this.booking);
    // this.selectedFilter = this.dateFilter[0].title;
    if (this.fromDate) {
      this.fromDate.setDate(this.fromDate.getDate() - 3 * 31);
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
    }
    if (this.toDate) {
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
    }
    this.getData();
  },
  methods: {
    showPopup(heads, item) {
      this.description = item;
      this.heads = heads;
      this.showDesc = true;
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/productcart/quotation/admingetlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          from: this.fromDate,
          to: this.toDate,
          count: this.count,
          page: this.currentPage,
          filter: this.selectedFilter,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.quoteData = response.data.data;
          this.pages = response.data.pages;
          this.excel = response.data.data;
          this.json_data = this.excel;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>

<style scoped>
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
td {
  font-family: poppinsregular;
}

th {
  font-family: poppinssemibold;
}
</style>
